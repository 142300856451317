.header-background{
  background-image: url('../assets/background_main.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1200px)  {
  .header-background{
    background-image: url('../assets/background_main.jpeg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .header-video-box{
    display: flex;
    justify-content: center;
  }
}

.header-grid-section{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}


.header-grid-container-large{
  max-width: 1280px;
}

.header-grid-container-small {
  padding-left: 1%;
}

.vertical-carousel {
  overflow: hidden;
  padding-bottom: 12px;
}

.header-video-box{
  position: relative;
  padding-top: 56.25%;
}

@media only screen and (max-width: 1228px){
  .header-video-box{
    min-height: 200px;
    width:100%;
    width: 300px;
    margin: 20px 24px 0px 24px;
  }
}

.css-1iaxp9j{
  max-width: 900px !important;
  width: 100% !important;
}

.carousel-slide {
  transition: transform 0.5s ease-in-out; /* Add smooth transition effect */
  transform: translateY(100%); /* Initially, move slides down */
}

.carousel-slide.active {
  transform: translateY(0); /* Move the active slide up */
  font-size: 24px;
  font-weight: 700;
}

.carousel-slide.inactive {
  display: none;
}

.just-reflect-section {
  padding: 50px;
  gap: 10px;
}

/* .css-1uwl3xt-MuiPaper-root-MuiAppBar-root{
  background-color: transparent !important;
} */

.header-video-section{
  color: white;
}

.subsription-section-alignment{
  background-color:#3d3d47;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 0px;
}

.subscribe-button{
  border: 1px solid #3d3d47;
  color: #fff;
  background: #f09711;
  border-radius: 24px;
}

.fb-link-btn {
  color: #fff;
}

.fb-link-btn:hover{
  color: #60d7f7;
}

.just-reflect-video-container{
  display: flex;
  justify-content: center;
}

.header-video-section{
  max-width: 630px;
  background:'#009CE0'
}
@media only screen and (max-width: 720px) {
  .header-video-section{
    min-height: 200px;
    width: 400px;
    margin: 20px 24px 0px 24px;
  }
}

@media only screen and (max-width: 900px) {
  .just-reflect-video-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricing-amount-btn{
    width: 95%;
    text-align: center;
    align-items: center;
    margin-left: 8px;
  }
  .pricing-card{
    margin-bottom: 12px;
  }
}

.just-reflect-video-box{
  min-height: 200px;
  width: 300px;
  margin: 20px 24px 0px 24px;
}

.who-is-for-list {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.who-is-for-section {
  background-color: #f1f1f1;
  padding: 30px 50px 30px 50px;
  gap: 10px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.who-is-it-for-blocks {
  border: 2px solid black;
  gap: 10px;
  background: #FAFAFA;
  padding: 30px 30px 30px 30px;
  max-width: 367px;
  max-height: 367px;
  width:100%;
  height:100%;
  font-size:20px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.who-is-it-for-grid-block {
  justify-content: center;
  display: flex;
}

.about-peter-covacs-section{
  max-width: 740px;
}

.about-peter-container{
  padding: 30px;
  background: #DCDCDC;
}

.testimonial-text{
  padding: 4px 40px;
}

@media only screen and (min-width: 1800px){
  .about-peter-covacs-section{
    max-width: 615px !important;
    margin-left: 34px;
  }

  .about-peter-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.list-of-modules{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 12px;
}

.popover-container{
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
}

.popover-header{
    background: #14213d;
    font-size: 26px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    padding-top: 14px;
    border-radius: 2px;
    margin-top: 8px;
    color: #f5f8fa;
}

.popover-content{
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popover-li{
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
}

/* .company-container{
  background-image: url('https://images.unsplash.com/photo-1487147264018-f937fba0c817?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

.company-container{
  padding: 50px;
  gap: 30px;
  background: #E2F1FF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.company-grid-item{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 8px;
}

.Taste-Of-Course-section{ 
    display: flex;
    justify-content:center;
    flex-direction: column;
   }

.what-you-will-learn-section{
  padding:30px 20px !important;
  gap: 30px;
  background: #F5F5F5;
}

.taste-of-course-list-item{
  min-height: 122px !important;
}

.results-section{
  padding:30px !important;
  gap: 30px;
  background: #E6E6E6;
}

.about-peter-grid{
  max-width: 1280px !important;
}

.header-logo{
  max-height: 320px; 
  width: 360px;
}


@media only screen and (min-width: 900px) {
  .pricing-card-grid{
    display: flex;
    justify-content: center;
    align-items: right;
    padding-right: 18px;
  }
  .pricing-card-grid-left{
    padding-left:18px;
  }
  .pricing-card{
    width: 640px;
  }
}

@media only screen and (max-width: 900px) {
  .pricing-card-grid{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pricing-card-grid-left{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pricing-card{
    width: 345px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-logo{
    padding-bottom: 30px !important;
  }
}

.testimonial-image-and-name-container{
  display: flex ;
  justify-content: center ;
}

.testimonail-name-and-post{
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.testimonail-image-container{
  margin-top: 6px;
  width: 70px;
  height: 70px;
  border: 1px solid #fff;
  border-radius: 12%;
}

.pricing-card-content{
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 25px 15px 25px;
}

.pricing-amount{
  display: flex;
  justify-content: center;
}

.pricing-amount-btn{
  display: flex;
  justify-content: center;
  padding: 0px 0px 20px 0px;
}

.enrollnow-btn{
  border: 1px solid #1DA1F2;
  padding: 12px 70px;
  border-radius: 22px;
  background: #1DA1F2;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

/* .carousel .control-arrow {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.carousel.carousel-slider .control-arrow {
  top: 50% !important;
  margin-top: -13px !important;
  color: #fff !important;
  font-size: 26px !important;
  display: block !important;
  z-index: 2 !important;
}

.carousel .control-prev.control-arrow {
  left: 0 !important;
}

.carousel .control-next.control-arrow {
  right: 0 !important;
}
 */

 .enrollnow-btn-coming-soon{
  width: 74%;
  text-align: center;
  border: 1px solid #e5e5e5 !important;
  padding: 12px 70px !important;
  border-radius: 7px !important;
  background: #FFA800 !important;
  color: #14213d !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}

.pricing-container{
  background: #009CE0;
  padding: 78px 50px 78px 50px;
}

.pricing-card{
  border: 1px solid #fff;
  border-radius: 12px !important;
}

/* .pricing-card:hover{
  border: solid 2px #1DA1F2;
} */

.whats-in-it-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px;
  gap: 25px;
}

.whats-in-it-content{
  max-width: 1280px;
}

.whats-in-it-begin-now-container{
  border: 2px solid #14213d;
  margin-bottom: 18px;
  width: 30%;
}

.what-is-in-it-grid-container{
    max-width: 1280px;
}

.collapse-container {

  width: 100%;
}

.collapse-button {
  display: block;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.collapse-content {
  overflow: hidden;
  padding: 8px;
  transition: max-height 0.3s ease;
  background: #FCFCFC;
}

.collapse-title-collapse {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #D8F1FF;
  color: #169DE6;
}

.collapse-title-expand{
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #59C5FF;
  color: #FFFFFF;
}

.faq-answers-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.custom-arrow {
  color: #212121;
  padding: 4px;
  border-radius: 50%;
}
.custom-arrow.left {
  left: 20px;
}
.custom-arrow.right {
  right: 20px;
}
